<template>
  <Dialog :visible="visible" @update:visible="visible = $event" modal :pt="{
    root: 'border-none',
    mask: {
      style: 'backdrop-filter: blur(3px)'
    }
  }">
    <template #container="{ closeCallback }">
      <Form @submit="submitData" :validation-schema="schema" v-slot="{ errors }">
        <div class="flex flex-column px-5 py-5 gap-4"
          style="border-radius: 12px; background-image: radial-gradient(circle at  top, var(--gray-200), var(--gray-900))">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="60" height="60"
            class="block mx-auto shine-effect"
            style="shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd"
            viewBox="0 0 1735.67 1724.274">
            <defs>
              <filter id="f2" x="0" y="0" width="200%" height="200%">
                <feOffset result="offOut" in="SourceGraphic" dx="20" dy="20" />
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
            <path
              d="M.09 6.007c1.413 3.534 42.753 86.214 91.867 184.087l89.04 177.374 685.823-.707 685.823-1.06 91.513-180.201c50.173-99.288 91.513-181.261 91.513-183.028 0-1.413-391.142-2.473-869.203-2.473C40.37-.001-2.384.353.089 6.005v.001zm237.441 469.935c0 2.12 52.293 106.354 443.789 882.63 87.274 172.78 164.301 325.775 171.014 339.908 6.714 14.133 13.427 25.794 15.193 25.794 1.413 0 2.473-216.241 2.473-480.536V763.202h482.301l73.494-144.867 73.847-144.867H868.586c-346.975 0-631.056 1.06-631.056 2.473l.001.001z"
              style="fill:#099;fill-rule:nonzero" />
          </svg>
          <div class="inline-flex flex-column gap-2">
            <div class="alert alert-danger col-md-8 offset-2" v-if="error">
              {{ errorDisplayText }}
            </div>
            <label for="username" class="text-primary-50 font-semibold">Benutzername</label>
            <InputText v-model="values.email" autocomplete="username" placeholder="xyz@email.com" id="username"
              class="form-control bg-white-alpha-20 border-none p-3 text-primary-50 w-20rem" />
          </div>
          <div class="inline-flex flex-column gap-2">
            <label for="password" class="text-primary-50 font-semibold">Passwort</label>
            <InputText v-model="values.password" as="input" autocomplete="new-password" id="new-password" name="new-password"
              class="form-control bg-white-alpha-20 border-none p-3 text-primary-50 w-20rem" type="password"
              placeholder="Passwort" />
          </div>
          <div class="inline-flex flex-column gap-2">
            <label for="password" class="text-primary-50 font-semibold">Passwort</label>
            <InputText v-model="values.confirmPassword" as="input" id="confirmPassword" autocomplete="new-password" name="confirmPassword"
              class="form-control bg-white-alpha-20 border-none p-3 text-primary-50 w-20rem" type="password"
              placeholder="Passwort wiederholen" />
          </div>
          <div class="text-white">
            <span class="pr-2 text-xl font-semibold">Konto erstellen</span><i class="pi pi-info-circle text-blue-300 text-xl"></i>
            <div class="pt-3">
            <p class="m-0 p-0">
              Nach dem Registrieren, bekommen Sie eine Email
            </p>
            <p class="m-0 p-0">
              mit einem Link, um die Email-Adresse zu bestätigen.
            </p>
          </div></div>
          <div class="flex align-items-center gap-3">
            <Button label="Abbrechen" @click="emit('hide');" text
              class="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"></Button>
            <Button :loading="isLoading" icon="pi pi-send" label="Registrieren" @click="submitData" text
              class="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"></Button>
          </div>
        </div>
      </Form>
    </template>
  </Dialog>
</template>

<script setup>
import { ref, reactive } from "vue";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";

defineProps({
  visible: Boolean
});
const emit = defineEmits(["hide"]);

const store = userStore();
const toast = useToast();

const values = reactive({
  email: "",
  password: "",
  confirmPassword: "",
})

const schema = yup.object().shape({
  email: yup
    .string()
    .required("E-Mail Adresse wird benötigt.")
    .trim()
    .email("Keine gültige E-Mail Adresse."),
  password: yup
    .string()
    .required("Password wird benötigt.")
    .min(6, "Password mindestens 6 Zeichen"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwörter stimmen nicht überein."),
});

const error = ref("");
const isLoading = ref(false);


const columnSizesForm =
  "col-md-8 offset-md-2 col-sm-8 offset-sm-2 col-8 offset-2";

function errorDisplayText() {
  if (error.value) {
    if (error.value.includes("EMAIL_EXISTS")) {
      return "E-Mail Adresse existiert bereits.";
    }
    return "Ein unbekannter Fehler, versuche es nochmal.";
  }
  return "";
}

function submitData() {
  isLoading.value = true;
  error.value = "";
  store
    .signup({
      email: values.email,
      password: values.password,
    })
    .then(() => {
      toast.add({
        severity: "success",
        summary: "Registrierung abgeschickt",
        detail: "Bitte die Registrierung per Email abschließen, TIP: Schauen Sie auch im Spam Ordner nach, falls keine Email da ist.",
        life: 3000,
      });
      store.registersStayOpen = false;
      console.log("🚀 ~ file: RegisterComponent.vue:129 ~ .then ~ store.registersStayOpen:", store.registersStayOpen)
      setTimeout(() => {
        emit("hide");
        isLoading.value = false;
      }, 3000);
    })
    .catch((error) => {
      error.value = error.message;
      isLoading.value = false;
    });
}
</script>

<style scoped></style>
