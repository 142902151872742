<template>
  <div
    v-if="showIntroText"
    class="top-50 sm:top-50 md:top-40 lg:top-65 xl:top-20"
    style="position: absolute; left: 5%; z-index: 1">
    <typical
      class="font-bold vt-title text-2xl md:text-3xl xl:text-8xl lg:text-8xl"
      style="color: #700"
      :steps="[homePageTitle, 1000]"
      :wrapper="'divs'"></typical>
    <typical
      class="vt-subTitle text-2xl md:text-4xl xl: text-6xl font-medium text-white"
      :steps="['', 1000, homePageSubTitle, 1000]"
      :loop="1"
      :wrapper="'div'"></typical>
  </div>
  <div
    class="card flex justify-content-center w-full h-30rem md:h-30rem overflow-hidden"
    style="background-color: #000">
    <Galleria
      :value="homeImages"
      :responsiveOptions="responsiveOptionsImg"
      :numVisible="5"
      :circular="true"
      :autoPlay="true"
      :transitionInterval="8000"
      containerStyle="max-width: 100%"
      :showItemNavigators="true"
      :showItemNavigatorsOnHover="true"
      :showThumbnails="false">
      <template #item="slotProps">
        <img
          :src="slotProps.item"
          style="
            max-height: 100%;
            max-width: 100%;
            display: block;
            object-fit: contain;
          " />
      </template>
    </Galleria>
  </div>

  <div class="card" v-if="sliderItems.length>0">
    <Carousel
      :value="sliderItems"
      :numVisible="numVisible3"
      :numScroll="2"
      :responsiveOptions="responsiveOptions"
      circular
      :autoplayInterval="3000">
      <template #item="slotProps">
        <div class="border-1 surface-border border-round m-2 p-3  ">
          <div class="mb-3">
            <div class="relative mx-auto">
              <img
                :src="slotProps.data.itemImageUrl"
                :alt="slotProps.data.itemName"
                class="max-h-17rem max-w-17rem border-round align-items-center justify-content-center" 
                style="object-fit: contain"
                />
              <Tag
                :value="slotProps.data.inventoryStatus"
                :severity="getSeverity(slotProps.data.inventoryStatus)"
                class="absolute"
                style="left: 5px; top: 5px" />
            </div>
          </div>
          <div class="mb-3 font-medium">{{ slotProps.data.itemName }}</div>
          <div class="flex justify-content-between align-items-center"></div>
        </div>
      </template>
    </Carousel>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { ProductService } from "@/service/ProductService";
import typical from "vue-typical";
import { userStore } from "@/store/userStore";

const store = userStore();
const showIntroText = ref(false);
const homeImages = ref([]);
const homePageTitle = ref();
const homePageSubTitle = ref();
const sliderItems = ref([]);
const numVisible3 = ref((sliderItems.length<3?sliderItems.length:3));
const numVisible4 = ref((sliderItems.length<4?sliderItems.length:4));

const responsiveOptionsImg = ref([
  {
    breakpoint: "991px",
    numVisible: numVisible4,
  },
  {
    breakpoint: "767px",
    numVisible: numVisible3,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
  },
]);
const responsiveOptions = ref([
  {
    breakpoint: "1400px",
    numVisible: numVisible3,
    numScroll: 1,
  },
  {
    breakpoint: "1199px",
    numVisible: numVisible3,
    numScroll: 1,
  },
  {
    breakpoint: "767px",
    numVisible: 2,
    numScroll: 1,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
    numScroll: 1,
  },
]);

onMounted(() => {
  if (store.companySettings.extendedShopSettings) {
    const data = store.companySettings.extendedShopSettings;
    (sliderItems.value = data.sliderItems),
      (homeImages.value = data.homeImages),
      (homePageTitle.value = data.homePageTitle),
      (homePageSubTitle.value = data.homePageSubTitle);
  } else {
    ProductService.getProductsData().then(
      (data) => (
        (sliderItems.value = data.sliderItems),
        (homeImages.value = data.homeImages),
        (homePageTitle.value = data.homePageTitle),
        (homePageSubTitle.value = data.homePageSubTitle)
      )
    );
  }
  setTimeout(() => {
    showIntroText.value = true;
  }, 1500);
  console.log(store.companySettings);
});

const getSeverity = (status) => {
  switch (status) {
    case "INSTOCK":
      return "success";

    case "LOWSTOCK":
      return "warning";

    case "OUTOFSTOCK":
      return "danger";

    default:
      return null;
  }
};
</script>
