import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";
import { reactive } from "vue";

export const getMappedAdresses = (data) => {
  return Object.values(data).map((d) => {
    return d;
  });
};

export const AddressService = {
  async getData() {
    try {
      let url = process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + "/shipping-address";
      console.log(url);
      const response = await iAxios.get(url);
      console.log("🚀 ~ file: InvoicesService.js:25 ~ getData ~ response.data:", response.data)
      // TODO warte auf faruks EP fix
      return [];
      //return response.data;
    } catch (exception) {
      console.log(exception);
    }
    return null;
  },

  async getAdresses() {
    return Promise.resolve(this.getData());
  },


  async getCustomerExtraAddressList() {
    let store = userStore();
    try {
      const extraAddress = await store.whoAmIData.extraAddress;
      const customerDetail = await store.whoAmIData;
      if (extraAddress) {
        const listExtraAddress = await reactive(extraAddress.list);
        if (listExtraAddress) {
          const mainAdress = {
            bezeichnung: 'Hauptadresse',
            name1: customerDetail.isPrivateCustomer ? customerDetail.salutation + ' ' + customerDetail.firstName + ' ' + customerDetail.surName : customerDetail.firmenName,
            name2: customerDetail.name2,
            street: customerDetail.street,
            houseNumber: customerDetail.houseNumber,
            address1: customerDetail.street + ' ' + customerDetail.houseNumber,
            address2: customerDetail.address2,
            postCode: customerDetail.postCode,
            city: customerDetail.city,
          };
          listExtraAddress.push(mainAdress);
          return listExtraAddress;
        }
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  },
};
