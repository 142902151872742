<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div
      class="flex flex-column md:flex-row justify-content-between align-items-center mb-4">
      <div class="flex flex-column text-center md:text-left">
        <span class="text-900 text-3xl font-medium mb-2">Meine Bestellungen</span
        >
      </div>
      <InputGroup class="mt-5 mb-2 md:mt-0 md:mb-0 w-full lg:w-25rem hidden lg:hidden"
>        
        <InputGroupAddon>
          <Button icon="pi pi-search"  class="p-button-rounded p-button-text"></Button>
        </InputGroupAddon>
        <InputText placeholder="Suche nach Bestellnummer oder Artikelname" class="w-full lg:w-25rem surface-100 surface-border" />
      </InputGroup>
    </div>
    <!--das erste element collapsed=false setzen-->
    <div v-for="(order, index) in orders" :key="order.id" class="surface-card grid grid-nogutter border-round shadow-2"> <!-- erste Forschleife sämtliche Bestellungen-->
      <Fieldset :legend="formatCurrency(order.docContent.documentValueNetto)+' | '+order.docName+ ' | '+order.created_de" class="w-full p-0 border 
      surface-100 border-round-top" :toggleable="true" :collapsed="index !== 0"
>
<template #legend>
        <div class="flex align-items-center gap-0 px-0 justify-content-between">

          <div class="w-8rem">
            {{formatCurrency(order.docContent.documentValueNetto)}}
          </div>
          <div>
            <span class="text-700">
              {{order.docName}}
            </span>
          </div>
        </div>
      </template>

      <div class="col-12 flex p-2 surface-100 border-round-top">
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-700 block">Bestellnummer</span
          ><span class="text-900 font-medium block mt-2"> {{order.docName}} </span>
        </div>
        <Divider layout="vertical" class="mx-0 lg:mx-3" />
        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-700 block">Bestelldatum</span
          ><span class="text-900 font-medium block mt-2"> {{order.created_de}} </span>
        </div>
        <Divider layout="vertical" class="mx-0 lg:mx-3" />

        <div class="p-2 flex-auto text-center md:text-left">
          <span class="text-700 block">Netto Gesamtsumme [ink. Ust]</span
          ><span class="text-900 font-medium block mt-2">{{formatCurrency(order.docContent.documentValueNetto)}}  [{{ formatCurrency(order.docContent.documentValueBrutto)}}]</span>
        </div>
      </div>
      <div v-for="item in order.itemList" class="col-12"> <!-- zweite Forschleife sämtliche Artikel-->
        <div
          class="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
          <div
            class="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
            <img
              :src="item.itemImageUrl?.[0]?.fileUrl.replace('/auth', '')"
              alt="product"
              style="object-fit: contain"
              class="w-8rem h-8rem mr-3 flex-shrink-0" />
            <div class="flex flex-column my-auto text-center md:text-left">
              <span class="text-900 font-medium mb-3 mt-3 lg:mt-0"
                ><span class="font-light">{{ item.quantity }} {{ item.itemUnit }}</span> {{item.itemName}}  </span
              ><small>EAN: {{ item.EAN }}</small>
              <Button outlined class="py-2 my-1 hidden lg:hidden "> 
              <span
                tabindex="0"
                class="cursor-pointer mx-auto lg:mx-0 text-sm text-center text-primary transition-duration-150 p-ripple"
                >Erneut kaufen für<span class="font-light pl-1">{{item.itemOutPrice}} €</span
                ></span>
              </Button>
            </div>
          </div>
          <div
            class="hidden lg:hidden bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center"
            style="border-radius: 2.5rem">
            <span
              class="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2"
              style="min-width: 2rem; min-height: 2rem"
              ><i class="pi pi-check"></i></span
            ><span class="text-green-600">geliefert am {shippingDate}</span>
          </div>
        </div>
        <Divider class="mx-0 lg:hidden" />
      </div>
      <div class="col-12 p-0 flex border-top-1 surface-border">
        <a
          tabindex="0"
          class="hidden lg:hidden cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
          style="border-bottom-left-radius: 6px"
          ><i class="pi pi-folder mr-2 mb-2 md:mb-0"></i>Archiviere Bestellung</a
        ><a
          tabindex="0"
          class="hidden lg:hidden  cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
          ><i class="pi pi-refresh mr-2 mb-2 md:mb-0"></i>Stornieren</a
        ><span v-if="order?.relationTo?.invoice" class="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
          tabindex="0"
         @click="downloadInvoice(order)" 
          ><i class="pi pi-download mr-2 mb-2 md:mb-0"></i>Rechnung herunterladen
      </span>
        <span v-else class="py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-gray-300  w-full"
>Rechnung noch nicht erstellt</span>
        <a
          tabindex="0"
          class="hidden lg:hidden  cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
          style="border-bottom-right-radius: 6px"
          ><i class="pi pi-comment mr-2 mb-2 md:mb-0"></i>Bewertung hinterlassen</a
        >
      </div>
    </Fieldset></div>
  
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { userStore } from '@/store/userStore';
import { useRouter } from 'vue-router'
import { onMounted } from 'vue';
import iAxios from '@/store/axiosInterface';
import { formatCurrency } from "@/utils/formatUtils";
import download from "downloadjs";

const store = userStore()
const router = useRouter()
const orders=ref({});

const downloadInvoice = async (event) => {

  console.log("🚀 ~ file: OrderHistoryView.vue:135 ~ event:", event)

  let url = process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + "/download-invoice/" + event.relationTo.invoice.invoiceDocumentId;

  await iAxios.get(url, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      download(response.data, "Rechnung_" + event.relationTo.invoice.invoiceDocName + ".pdf", "application/pdf");
    })
};



onMounted(() => {
  console.log("orderhistoty");
  let url="/auth/customer/history";
  iAxios.get(url).then((response) => {
    console.log("response.data",response.data);
    orders.value=response.data.customerHistory.orders;

    console.log("🚀 ~ file: OrderHistoryView.vue:145 ~ orders:", orders)

  }); 

})
</script>
