<template v-if="formData">
    <Sidebar v-model:visible="sideAddressShow" header="Sidebar" position="right"
        class="w-11 h-10 lg:h-10 md:w-8 lg:w-4 p-3">
        <template #container="{ closeCallback }">
            <div class="field w-full">
                <div>
                    <Button type="button" @click="closeCallback" icon="pi pi-arrow-left" label="Zurück" outlined
                        class="text-color"></Button>
                </div>
            </div>
            <AdressAuswahl @addressSelected="addressSelected($event)" :addressType="addressType" />
        </template>
    </Sidebar>
    <div class="grid py-0">
        <div class="col-12 md:col-6 lg:col-6" v-if="formData.receiver">
            <div class="col-12 md:col-6 lg:col-6">
                <a href="#" @click="selectRechnungsAdresse" class="underline text-xs">Adressauswahl</a>
            </div>
            Rechnungsanschrift
            <div>
                <div>{{ formData.receiver.name1 }}</div>
                <div>{{ formData.receiver.name2 }}</div>
                <div>{{ formData.receiver.address1 }}</div>
                <div>{{ formData.receiver.address2 }}</div>
                <div>{{ formData.receiver.postCode }} {{ formData.receiver.city }}</div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-6">
            <div class="col-12 md:col-6 lg:col-6 text-xs">
                <a href="#" @click="selectLieferAdresse" class="underline text-xs">Adressauswahl</a>
            </div>
            Lieferanschrift
            <div v-if="formData.deliverTo">
                <div>{{ formData.deliverTo.name1 }}</div>
                <div>{{ formData.deliverTo.name2 }}</div>
                <div>{{ formData.deliverTo.address1 }}</div>
                <div>{{ formData.deliverTo.address2 }}</div>
                <div>{{ formData.deliverTo.postCode }} {{ formData.deliverTo.city }}</div>
            </div>
            <div v-else>
                Gleiche Adresse wie Rechnungsadresse
            </div>
        </div>
    </div>
</template>

<script setup>
import { userStore } from '@/store/userStore';
import AdressAuswahl from './AdressAuswahl.vue';
import { onMounted } from 'vue';
import { ref } from 'vue';

const store = userStore();

const formData = defineModel();
const sideAddressShow = ref(false);
const addressType = ref("");

onMounted(() => {
    console.log(formData.value.receiver);
});

const selectRechnungsAdresse = () => {
    addressType.value = 'Rechnungsanschrift';
    sideAddressShow.value = true;
}

const selectLieferAdresse = () => {
    addressType.value = 'Lieferanschrift';
    sideAddressShow.value = true;
}

const addressSelected = (event) => {
    const address = event.extraAddress;
    const addressType = event.addressType;
    if (addressType == "Rechnungsanschrift") {
        formData.value.receiver = {};
        formData.value.receiver.name1 = address.name1;
        formData.value.receiver.name2 = address.name2;
        formData.value.receiver.address1 = address.address1;
        formData.value.receiver.address2 = address.address2;
        formData.value.receiver.postCode = address.postCode;
        formData.value.receiver.city = address.city;
    } else {
        formData.value.deliverTo.name1 = address.name1;
        formData.value.deliverTo.name2 = address.name2;
        formData.value.deliverTo.address1 = address.address1;
        formData.value.deliverTo.address2 = address.address2;
        formData.value.deliverTo.postCode = address.postCode;
        formData.value.deliverTo.city = address.city;
    }
    sideAddressShow.value = false;
};
</script>