<template>
  <!--   <div>
    <span class="cursor-pointer p-3 m-3 bg-primary" @click="test1 = !test1"
      >Artikelbeschreibung test</span
    >
    <span class="cursor-pointer p-3 m-3 bg-primary" @click="test2 = !test2"
      >Preis test</span
    >
  </div> -->
  <div class="card">
    <ScrollPanel style="width: 100%; height: calc(100vh - 160px)" class="p-0 m-0" :step="10">
      <DataView :value="store.products" :layout="layout" paginator :alwaysShowPaginator="false" paginatorPosition="top"
        :rows="12">
        <template #header>
          <div class="grid">
            <div v-if="suche" class="col-11">
              Such Ergebnisse für: {{ suche }}
            </div>
            <div v-else class="col-11">
              <Breadcrumb :home="home" :model="breadcrumbState.breadcrumbItems" class="border-none" />
              <!--:pt="{root:{style:{'background-color':'transparent'}}}"-->
            </div>
            <div class="col-1">
              <DataViewLayoutOptions v-model="layout" />
            </div>
          </div>
        </template>
        <template #empty>
          <div class="flex flex-column justify-content-center align-items-center p-5">
            <i class="pi pi-shopping-cart text-4xl text-primary-50"></i>
            <div class="text-lg text-center mt-3">Keine Produkte gefunden</div>
          </div>
        </template>

        <template #list="slotProps">
          <div class="grid grid-nogutter">
            <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">
              {{ console.log("item", item) }}
              <div class="flex flex-column sm:flex-row sm:align-items-center p-4 gap-3"
                :class="{ 'border-top-1 surface-border': index !== 0 }">
                <div class="md:w-10rem relative">
                  <div v-if="item.itemIsNew && store.companySettings.showNewProducts" class="ribbon ribbon-left blue">
                    <span>NEU</span>
                  </div>
                  <div v-if="item.rabatt" class="ribbon red">
                    <span v-if="store.loggedIN">{{ getPrice(item) }}€</span><span v-else> .. % </span>
                  </div>
                  <img class="block xl:block mx-auto border-round max-w-8rem max-h-8rem" :src="item?.itemImageUrl?.[0]?.fileUrl.replace('/auth', '') ??
      require('@/assets/no-image.jpg')
      " :alt="item.itemName" />
                </div>
                <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                  <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                    <div>
                      <div class="text-lg font-medium text-900 mt-2">
                        {{ item.itemName }}
                      </div>
                      <span class="font-medium text-secondary text-sm">
                        {{ item.itemDescription }}</span>
                    </div>
                    <div class="surface-100 p-1" style="border-radius: 30px"></div>
                  </div>
                  <div class="flex flex-column md:align-items-end gap-5">
                    <div class="flex">
                      <span>
                        <Badge :value="getStockLabel(item)" :severity="getSeverity(item)" class="relative mr-2"></Badge>
                      </span>

                      <span v-if="!item?.VK"><Button text label="Anmelden für Preis" @click="showLogin = true" /></span>




                      <span v-else class="text-2xl font-semibold text-900">
                        <span v-if="item.rabatt" class="price-wrapper">
                          <span class="strike-through w-5rem"></span>
                          <span class="price" :decimalPlace="getSplittetPrice(item).decimalPlace">
                            {{ getSplittetPrice(item).preDecimalPlace }},
                          </span>
                        </span>
                        <span v-else class="price" :decimalPlace="getSplittetPrice(item).decimalPlace">{{
      getSplittetPrice(item).preDecimalPlace }},</span><span class="eur">EUR</span>
                      </span>
                    </div>
                    <div v-if="store.loggedIN" class="flex flex-row-reverse md:flex-row gap-2">
                      <Button outlined="" :icon="isFavorites(item)" icon="text-xl"
                        class="w-3rem h-3rem hover:surface-200  transition-colors transition-duration-300"
                        @click="$event.stopPropagation(); toggleFavorite(item)" @mousedown="$event.stopPropagation()"
                        @dblclick="$event.stopPropagation()" />
                      <FloatLabel class="flex-auto md:flex-initial white-space-nowrap">
                        <Dropdown v-if="item.VPE && item?.VPE?.list" v-model="item.VPE_QTY" optionLabel="VPE_QTY"
                          optionValue="VPE_QTY" :options="item.VPE.list" class="w-full" required id="vpe"
                          :pt="{ trigger: { class: 'max-w-0 pr-2' } }">
                          <template #dropdownicon>
                            <span></span>
                          </template>
                        </Dropdown>
                        <label for="vpe">Verpackungseinheit:</label>
                      </FloatLabel>
                      <Button icon="pi pi-shopping-cart" label="in den Warenkorb"
                        :disabled="item.inventoryStatus === 'OUTOFSTOCK'" @click="addToCart(item)"
                        class="flex-auto md:flex-initial white-space-nowrap"></Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <template #grid="slotProps">
          <div class="grid grid-nogutter">
            <div v-for="(item, index) in slotProps.items" :key="index"
              class="col-12 sm:col-6 md:col-4 xl:col-3 xxl:col-2 p-2">
              <div class="p-3 border-1 surface-border surface-card border-round flex flex-column h-20rem xl:h-26rem">
                <div class="text-base sm:text-sm lg:text-lg text-900 overflow-hidden" style="height: 4rem"
                  @click="openItemDetailsView(item)">
                  {{ item.itemName }}
                  <small v-if="test1" class="text-xs max-h-2rem overflow-y-hidden font-light text-400">{{
      item.itemDescription }}</small>
                </div>
                <div class="surface-50 flex justify-content-center border-round p-3" @click="openItemDetailsView(item)">
                  <div class="relative mx-auto h-8rem xl:h-15rem justify-content-center">
                    <div v-if="item.itemIsNew && store.companySettings.showNewProducts" class="ribbon ribbon-left blue">
                      <span>NEU</span>
                    </div>
                    <div v-if="item.rabatt" class="ribbon red">
                      <span v-if="store.loggedIN">{{ getPrice(item) }}€</span><span v-else> .. % </span>
                    </div>

                    <img class="border-round max-w-8rem max-h-8rem xl:max-w-15rem xl:max-h-15rem" :src="item?.itemImageUrl?.[0]?.fileUrl.replace('/auth', '') ??
      require('@/assets/no-image.jpg')
      " :alt="item.itemName" />
                    <Button text :icon="isFavorites(item)" icon="text-xl"
                      class="w-3rem h-3rem hover:surface-200 absolute transition-colors transition-duration-300"
                      @click="$event.stopPropagation(); toggleFavorite(item)" @mousedown="$event.stopPropagation()"
                      @dblclick="$event.stopPropagation()" />

                  </div>
                </div>
                <div class="flex gap-2 relative" style="left: 40px; top: -50px">
                  <Button v-if="store.loggedIN" @click="addToCart(item)" icon="pi pi-shopping-cart"
                    label="In den Warenkorb" :disabled="item.inventoryStatus === 'OUTOFSTOCK'"
                    class="border-1 border-white-alpha-20 border-round py-2 px-3 absolute bg-black-alpha-30 text-white inline-flex align-items-center justify-content-center hover:bg-black-alpha-40 transition-colors transition-duration-300 cursor-pointer p-ripple"></Button>
                </div>
                <div class="flex gap-2 relative" style="left: 15px; top: -110px">
                  <div class="card flex justify-content-center">
                    <InputNumber v-model="quantities[item.item_id_company]" v-if="store.loggedIN &&
      (quantities[item.item_id_company] =
        quantities[item.item_id_company] || 1)
      " showButtons buttonLayout="vertical" style="width: 2rem" :min="0" :max="999" inputClass="text-xs p-2"
                      buttonClass=""
                      class="text-xs border-round absolute text-white inline-flex align-items-center justify-content-center hover:bg-black-alpha-40 transition-colors transition-duration-300 cursor-pointer p-ripple">
                      <template #incrementbuttonicon>
                        <span class="pi pi-plus text-xs" />
                      </template>
                      <template #decrementbuttonicon>
                        <span class="pi pi-minus text-xs" />
                      </template>
                    </InputNumber>
                  </div>
                </div>
                <div class="pt-2">
                  <div v-if="test2" class="flex flex-column">
                    <span v-if="!item?.VK"><Button text label="Anmelden für Preis" @click="showLogin = true" /></span>
                    <span v-else class="text-2xl font-semibold text-900">
                      <span v-if="item.rabatt" class="price-wrapper">
                        <span class="strike-through w-5rem"></span>
                        <span class="price" :decimalPlace="getSplittetPrice(item).decimalPlace">
                          {{ getSplittetPrice(item).preDecimalPlace }},
                        </span>
                      </span>
                      <span v-else class="price" :decimalPlace="getSplittetPrice(item).decimalPlace">{{
      getSplittetPrice(item).preDecimalPlace }},</span><span class="eur">EUR</span>
                      <span class="pl-2">
                        <Badge :value="getStockLabel(item)" :severity="getSeverity(item)" class="relative"
                          style="left: 10px"></Badge>
                      </span>
                    </span>
                  </div>
                  <div v-if="!test2" class="flex flex-column">
                    <span v-if="!item?.VK"><Button text label="Anmelden für Preis" @click="showLogin = true" />
                      <span>
                        <Badge :value="getStockLabel(item)" :severity="getSeverity(item)" class="relative"
                          style="left: 10px"></Badge>
                      </span>
                    </span>
                    <span v-else class="text-2xl font-semibold text-900">
                      <span v-if="item.rabatt">
                        <span><s>{{ item?.VK?.toFixed(2) }}</s></span>
                      </span>
                      <span v-else>
                        <span>{{ item?.VK?.toFixed(2) }}</span> </span><span>€</span>
                      <Badge :value="getStockLabel(item)" :severity="getSeverity(item)" class="relative"
                        style="left: 10px"></Badge>
                    </span>
                  </div>
                  <div class="flex flex-row justify-content-between align-items-start gap-2">
                    <div></div>
                    <small v-if="!test1" class="max-h-2rem overflow-y-hidden">{{
      item.itemDescription
                      }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </DataView>
    </ScrollPanel>
  </div>
</template>

<script setup>
import { ref, inject, onMounted, reactive, defineAsyncComponent } from "vue";
import { userStore } from "@/store/userStore";
import { useToast } from "primevue/usetoast";
import { useDialog } from "primevue/usedialog";
import iAxios from "@/store/axiosInterface";
import { addToCart, quantities } from "@/utils/shoppingCartUtils";


const test1 = ref(true);
const test2 = ref(true);
const suche = ref(null);
const breadcrumbState = inject("breadcrumbState");
const showLogin = inject("showLogin");
const dialog = useDialog();
const toast = useToast();
const ItemDetailsView = defineAsyncComponent(() =>
  import("@/views/ItemDetailsView.vue")
);
const store = userStore();
const favorites = ref(null);
const layout = ref("grid");
const currentCategoryKey = store.currentCategory?.key;
const formData = reactive({
  quantity: 1,
});

const cItems = ref({
  item: null,
  quantity: 0,
});

const home = { icon: "pi pi-home", url: "/home" };

const getPrice = (item) => {
  if (item.rabatt) {
    return (item.VK - item.rabatt).toFixed(2);
  } else {
    return item.VK.toFixed(2);
  }
};

const getSplittetPrice = (item) => {
  let price;
  if (item.VPE_QTY) {
    price = (item.VK * item.VPE_QTY).toFixed(2);
  } else {
    price = item.VK.toFixed(2);
  }
  const [preDecimalPlace, decimalPlace] = price.split(".");

  return {
    preDecimalPlace: parseInt(preDecimalPlace, 10),
    decimalPlace: decimalPlace.padStart(2, "0"),
  };
};

const isFavorites = (item) => {
  if (store.favorites) {
    if (store.favorites.find((o) => o.item_id_company === item.item_id_company)) {
      return "pi pi-heart-fill text-yellow-300";
    }
  } return "pi pi-heart";

};

const categoryItemsLaden = () => {
  if (currentCategoryKey) {

    console.log("🚀 ~ file: CategoryItemsView.vue:373 ~ currentCategory:", currentCategoryKey)

    let url =
      "/customer/itemlist/" + store.companySettings.cid + "/" + currentCategoryKey;
    if (store.loggedIN) {
      url = "/auth/customer/itemlist/" + currentCategoryKey;
    }
    iAxios.get(url).then((response) => {
      store.products = response.data.itemList;
    });
  }
};


const openItemDetailsView = async (item) => {
  categoryItemsLaden();
  console.log("🚀 ~ file: CategoryItemsView.vue:373 ~ currentCategory:", currentCategoryKey)

  dialog.open(ItemDetailsView, {
    props: {
      header: item.itemName,
      maximizable: true,
      modal: true,
      style: {
        width: "65vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    data: {
      item: item,
    },
    onClose: async (options) => {
      if (options.data) {
      }
    },
  });
};

const toggleFavorite = (item) => {
  if (store.favorites) {
    if (store.favorites.find((o) => o.item_id_company === item.item_id_company)) {
      removeFavorite(item.item_id_company);
    } else {
      addFavorite(item.item_id_company);
    }
  } else {
    addFavorite(item.item_id_company);

  }
};

const addFavorite = (item_id_company) => {
  const favorites = store.favorites || [];
  favorites.push({ item_id_company });
  store.setFavorites(favorites);
};



const removeFavorite = (item_id_company) => {
  const favorites = store.favorites;
  const index = favorites.findIndex((o) => o.item_id_company === item_id_company);
  favorites.splice(index, 1);
  store.setFavorites(favorites);
};

onMounted(async () => {
  if (!store.favorites) {
    favorites.value = store.getFavorites;
  }
  if (store.loggedIN) {
    let url = "/auth/customer/itemlist/" + currentCategoryKey;
    iAxios.get(url).then((response) => {
      store.products = response.data.itemList;

      console.log(store.products);
    });
  }
});

const getStockLabel = (item) => {
  switch (item.inventoryStatus) {
    case "INSTOCK":
      return "Verfügbar";

    case "LOWSTOCK":
      return "Bald Ausverkauft";

    case "OUTOFSTOCK":
      return "Bald wieder verfügbar";

    default:
      return null;
  }
};

const getSeverity = (product) => {
  switch (product.inventoryStatus) {
    case "INSTOCK":
      return "success";

    case "LOWSTOCK":
      return "warning";

    case "OUTOFSTOCK":
      return "danger";

    default:
      return null;
  }
};
</script>
