<template>
  <div class="text-2xl pb-4 flex justify-content-center">
    <span v-if="addressType">{{ addressType }} auswählen</span>
    <span v-else>Alle Adressen</span>
  </div>
  <div v-if="!delievryAddressAvailable" class="flex align-items-center gap-2">
    <span>Lieferadresse = Rechnungsadresse</span>
    <Button outlined size="small" icon="pi pi-ellipsis-h" @click="toggle" aria-haspopup="true"
      aria-controls="overlay_menu" />
    <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />

  </div>
  <div v-else>
    <Button outlined class="mb-2" size="small" icon="pi pi-plus" label="weitere Adresse hinzufügen"
      @click="openExtraAddressDialog()" />
  </div>

  <div v-if="extraAddressList" style="overflow-y: auto;">
    <template v-if="extraAddressList" v-for="(extraAddress, index) in extraAddressList">
      <div class="pb-2">
        <Card
          :pt="{ body: { class: 'bg-primary-50 border-round-lg' }, content: { class: 'p-0 m-0' }, footer: { class: 'p-0 m-0' } }">
          <template #header>
            <div class=" bg-primary-100">
              <div v-if="extraAddress.bezeichnung != 'Hauptadresse'">
                <Button text icon="pi pi-pencil" icon-pos="right" class="p-button-rounded p-button-text p-button-plain"
                  @click="openExtraAddressDialog(index, 'edit')" :label="extraAddress.bezeichnung" />
              </div>
              <div v-else>
                <span class="flex justify-content-start text-lg text-gray-400 font-bold	p-3">{{ extraAddress.bezeichnung
                  }}</span>
              </div>
            </div>
          </template>
          <template #content>
            <div>{{ extraAddress.name1 }}</div>
            <div>{{ extraAddress.name2 }}</div>
            <div>{{ extraAddress.street }} {{ extraAddress.houseNumber }}</div>
            <div>{{ extraAddress.address2 }}</div>
            <div>{{ extraAddress.postCode }} {{ extraAddress.city }}</div>
          </template>
          <template #footer>
            <div class="flex justify-content-end">
              <Button v-if="addressType" label="Adresse auswählen" icon-pos="right" severity="info" icon="pi pi-check"
                text @click="emitAdresse(extraAddress)" />
            </div>
          </template>
        </Card>
      </div>
    </template>
  </div>
</template>
<script setup>
//es soll ein emit definiert werden das der elternkompnente die ausgewählte adresse liefert mit dem adresstypen
import { ref, computed, onMounted, defineAsyncComponent, reactive, markRaw } from "vue";
import { AddressService } from "@/service/AddressService";
import { useDialog } from "primevue/usedialog";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";
import { userStore } from "@/store/userStore";
const dialog = useDialog();
const { addressType } = defineProps({
  addressType: String,
});
const extraAddressList = ref([]);
const auswahlTitel = ref("Lieferanschrift");

const store = userStore();

const toast = useToast();

const emit = defineEmits(["addressSelected"]);

const emitAdresse = (extraAddress) => {
  extraAddress.address1 = extraAddress.street + " " + extraAddress.houseNumber;
  const addressSelected = {
    addressType: addressType,
    extraAddress: extraAddress
  }
  emit("addressSelected", addressSelected);
};

const delievryAddressAvailable = computed(() => {
  if (!extraAddressList.value) return false;
  return extraAddressList.value.find((extraAddress) => extraAddress.bezeichnung === "Lieferadresse");
});
const menu = ref();
const items = ref([
  {
    label: 'Options',
    items: [
      {
        label: 'Lieferadresse hinzufügen',
        icon: 'pi pi-truck',
        disabled: () => delievryAddressAvailable.value,
        command: () => { openExtraAddressDialog("Lieferadresse"); }
      },
      {
        label: 'weitere Adressen hinzufügen',
        icon: 'pi pi-plus',
        command: () => { openExtraAddressDialog(); }
      }
    ]
  }
]);
const toggle = (event) => {
  menu.value.toggle(event);
};
const extraAddressDialog = defineAsyncComponent(() =>
  import("@/views/ExtraAddressInputDialog.vue")
);
const ExtraAddressInputDialogHeader = defineAsyncComponent(() =>
  import("@/views/ExtraAddressInputDialogHeader.vue")
);
const openExtraAddressDialog = async (event, proceedType) => {
  let adressIndex = 0;
  const isLieferAdresse = ref(false);

  let isNew = true;
  console.log("🚀 ~ openExtraAddressDialog ~ event:", event)
  const newextraAddressData = reactive({
    bezeichnung: "",
    name1: "",
    name2: "",
    street: "",
    houseNumber: "",
    address1: "",
    address2: "",
    postCode: "",
    city: "",
  });
  let extraAddressData = newextraAddressData;
  switch (event) {
    case "Lieferadresse":
      adressIndex = 0;
      isLieferAdresse.value = true;
      newextraAddressData.bezeichnung = "Lieferadresse";
      break;
    default:
      console.log("🚀 ~ openExtraAddressDialog ~ event:", event)

      adressIndex = parseInt(event);
      break;
  }
  if (proceedType == "edit") {
    extraAddressData = extraAddressList.value[adressIndex];
    isLieferAdresse.value = extraAddressData.bezeichnung === "Lieferadresse";
    isNew = false;
  }

  dialog.open(extraAddressDialog, {
    props: {
      modal: true,
      closable: true,
      style: {
        width: "45vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      header: markRaw(ExtraAddressInputDialogHeader),
    },
    data: {
      extraAddressData,
      isNewExtraAddress: isNew,
      isLieferAdresse: isLieferAdresse.value,
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        const extraAddressUid = options.data.extraAddressUid;
        if (buttonType == "Save") {
          if (isNew) {
            extraAddressList.value.push(options.data.extraAddressData);
            saveCustomerExtraAddress();
          } else {
            extraAddressList.value[adressIndex] = options.data.extraAddressData;
            saveCustomerExtraAddress();
          }
        } else if (buttonType == "Delete") {
          extraAddressList.value.splice(adressIndex, 1);
        }
      }
    },
  });
};

const saveCustomerExtraAddress = async () => {
  let url = process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + "/customer-details";
  const customerData = {};
  const extraAddressListFilteres = extraAddressList.value.filter(item => item.bezeichnung != 'Hauptadresse');
  customerData.extraAddress = { list: extraAddressListFilteres };
    iAxios.patch(url, customerData).then((response) => {
      store.userData = response.data.customer;
      toast.add({ severity: "success", summary: "Adresse wurde abgespeichert.", life: 3000 });
    }).catch((e) => {
      toast.add({ severity: "danger", summary: "Adresse konnte nicht abgespeichert werden.", life: 3000 });
      console.log(e);
    });
};

onMounted(async () => {
  let addressList = await AddressService.getCustomerExtraAddressList();
  if (addressList) {
    extraAddressList.value = addressList;
  }
});

</script>
<style scoped></style>