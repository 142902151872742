import iAxios from "@/store/axiosInterface";

/* export const getKundens = (data) => {
  return Object.values(data).map((d) => {
    d.birthDate = new Date(d.birthDate);
    return d;
  });
}; */
export const getMappedInvoices = (data) => {
  return Object.values(data).map((d) => {
    return d;
  });
};

export const InvoicesService = {
  async getData() {
    try {
      let url = process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + "/invoices-all";
      const response = await iAxios.get(url, {});
      console.log("🚀 ~ file: InvoicesService.js:25 ~ getData ~ response.data:", response.data)
      return response.data;
    } catch (exception) {
      console.log(exception);
    }
    return null;
  },

  async getInvoices() {
    return Promise.resolve(this.getData());
  },
};
