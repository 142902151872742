<template>
    <div class="grid">
        <div class="col-6 md:col-6 lg:col-4 th">
            <strong>Produkt</strong>
        </div>
        <div class="col-6 md:col-5 lg:col-2 th">
            <strong>Stückpreis</strong>
        </div>
        <div class="col-6 md:col-6 lg:col-2 th">
            <strong>Menge</strong>
        </div>
        <div class="col-6 md:col-6 lg:col-2 th">
            <strong>Steuersatz</strong>
        </div>
        <div class="col-6 md:col-6 lg:col-2 th">
            <strong>Summe</strong>
        </div>
    </div>
    <div class="grid" v-for="product in store.shoppingCart" :key="product.item.item_id_company">
        <div class="col-6 md:col-6 lg:col-4" style="display: flex; align-items: center;">
            <img :src="product.item.itemImageUrl?.[0]?.fileUrl.replace('/auth', '') ?? require('@/assets/no-image.jpg')"
                class="checkout-product-image" />
            <div style="vertical-align: middle;">{{ product.item.itemName }}  <template v-if="product.item.VPE_QTY">(VPE: {{ product.item.VPE_QTY }})</template></div>
        </div>
        <div class="col-6 md:col-6 lg:col-2">{{ formatCurrency(product.item.VPE_QTY ? product.item.VK * product.item.VPE_QTY : product.item.VK) }}</div>
        <div class="col-6 md:col-6 lg:col-2">{{ product.quantity }}</div>
        <div class="col-6 md:col-6 lg:col-2">{{ product.item.itemTaxValue }}%</div>
        <div class="col-6 md:col-6 lg:col-2" v-tooltip="'Brutto ' + formatCurrency(product.item.VPE_QTY ? (product.item.VK * product.item.VPE_QTY * product.quantity * (1+(product.item.itemTaxValue)/100)) : (product.item.VK * product.quantity * (1+(product.item.itemTaxValue)/100)))">{{ formatCurrency(product.item.VPE_QTY ? product.item.VK * product.item.VPE_QTY * product.quantity : product.item.VK * product.quantity) }}
        </div>
    </div>
    <!-- Zusammenfassung -->
    <div class="flex-container">
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-8"></div>
            <div class="col-12 md:col-12 lg:col-2">Gesamtnetto:</div>
            <div class="col-12 md:col-12 lg:col-2">{{ formatCurrency(model.docContent.documentValueNetto) }}</div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-8"></div>
            <div class="col-12 md:col-12 lg:col-2"></div>
            <div class="col-12 md:col-12 lg:col-2"></div>
        </div>
        <template v-for="(tax, index) in calcTaxValue" >
            <div class="grid" v-if="index != 'taxSum'">
                <div class="col-12 md:col-12 lg:col-8"></div>
                <div class="col-12 md:col-12 lg:col-2">{{ index*100 }}% Ust.:</div>
                <div class="col-12 md:col-12 lg:col-2">{{ formatCurrency(tax) }}</div>
            </div>
        </template>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-8"></div>
            <div class="col-12 md:col-12 lg:col-2"></div>
            <div class="col-12 md:col-12 lg:col-2"></div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-8"></div>
            <div class="col-12 md:col-12 lg:col-2">Zwischensumme:</div>
            <div class="col-12 md:col-12 lg:col-2">{{ formatCurrency(calcZwischenSumme) }}</div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-8"></div>
            <div class="col-12 md:col-12 lg:col-2">Versand/Lieferung:</div>
            <div class="col-12 md:col-12 lg:col-2">{{ formatCurrency(calcVersandkosten) }}</div>
        </div>
        <div class="grid">
            <div class="col-12 md:col-12 lg:col-8"></div>
            <div class="col-12 md:col-12 lg:col-2">Gesamtsumme:</div>
            <div class="col-12 md:col-12 lg:col-2">{{ formatCurrency(calcGesamtsumme) }}</div>
        </div>
    </div>

</template>

<style>
.flex-container {
    flex-direction: column;
    align-items: flex-end;
    object-fit: contain;
}

.th {
    padding: 8px;
    text-align: left;
    color: #666;
    border: none;
    /* Rahmen entfernen */
}

.checkout-product-image {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 10px;
}
</style>


<script setup>
import { userStore } from "@/store/userStore";
import { formatCurrency } from "@/utils/formatUtils";
import { useConfirm } from "primevue/useconfirm";
import * as shoppingCartUtils from "../utils/shoppingCartUtils";
import { ref } from "vue";
import { onMounted } from "vue";

const store = userStore();
const confirm = useConfirm();

const model = defineModel();

const calcZwischenSumme = ref(0);
const calcVersandkosten = ref(5);
const calcGesamtsumme = ref(0);
const calcTaxValue = ref(0);

onMounted(() => {
    calcTaxValue.value = shoppingCartUtils.calculateTaxValue(model.value);

    console.log(store.shoppingCart);
    shoppingCartUtils.calculateGesamtNetto(model.value);
    const gesamtBrutto = parseFloat(shoppingCartUtils.calculateGesamtBrutto(model.value.itemList));
    model.value.docContent.documentValueBrutto = gesamtBrutto + model.value.docContent.shipping.shippingPrice;
    
    calcZwischenSumme.value = shoppingCartUtils.calculateGesamtBrutto(model.value.itemList);
    calcGesamtsumme.value = calcZwischenSumme.value + calcVersandkosten.value;
});



</script>