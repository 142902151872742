<template>
    <div class="flex flex-column">
        <div v-if="!store.shoppingCart || store.shoppingCart.length == 0"
            class="surface-overlay p-4 flex align-items-center justify-content-between">
            <span class="text-900 text-xl font-small">Keine Waren im Warenkorb</span>
        </div>
        <div v-else class="flex flex-auto overflow-y-auto">
            <div class="w-full p-4 flex flex-column">
                <div v-for="product in store.shoppingCart" :key="product.item.item_id_company"
                    class="flex align-items-start md:align-items-center border-top-1 surface-border">
                    <!-- Forschleife begin-->
                    <img style="object-fit: contain" :src="product.item.itemImageUrl?.[0]?.fileUrl.replace(
            '/auth',
            ''
        ) ?? require('@/assets/no-image.jpg')
            " class="h-4rem sm:w-7rem sm:h-7rem flex-shrink-0" />
                    <div class="pl-3 flex-auto">
                        <div class="flex justify-content-between b-2 mt-3 w-full">
                            <div class="w-full" v-tooltip="'Bruttopreis: ' + formatCurrency((product.item.VPE_QTY ? product.item.VK * product.item.VPE_QTY * product.quantity:product.item.VK * product.quantity) * (1+(product.item.itemTaxValue)/100))">
                                <div class="text-primary font-medium w-full">{{ product.item.itemName }} <template v-if="product.item.VPE_QTY">VPE: {{ product.item.VPE_QTY }}</template></div>
                                <!--div class="text-500 max-h-4rem h-3rem overflow-y-hidden w-full">{{
            product.item.itemDescription }}
                                </div-->
                                <a v-if="!product.item.linkedItem" class="cursor-pointer text-pink-500 hover:text-pink-700 transition-colors transition-duration-300"
                                    @click="removeFromCart(product)"><i class="pi pi-trash"></i>
                                </a>

                                <div class="justify-content-end">
                                    Einzelpreis: {{ formatCurrency(product.item.VPE_QTY ? product.item.VK * product.item.VPE_QTY : product.item.VK) }}
                                </div>
                                <div class="justify-content-end">
                                    Gesamtpreis: {{ formatCurrency(product.item.VPE_QTY ? product.item.VK * product.item.VPE_QTY * product.quantity:product.item.VK * product.quantity) }}
                                </div>
                            </div>
                            <InputNumber v-model="product.quantity" showButtons buttonLayout="vertical" :min="0"
                                :max="999" inputClass="text-xs" @blur="amountChange(product, confirm)" buttonClass=""
                                style="width: 35px;" :disabled="product.item.linkedItem">
                                <template #incrementbuttonicon v-if="!product.item.linkedItem">
                                    <span class="pi pi-plus text-xs" />
                                </template>
                                <template #decrementbuttonicon v-if="!product.item.linkedItem">
                                    <span class="pi pi-minus text-xs" />
                                </template>
                            </InputNumber>
                        </div>
                    </div>
                    <!-- Forschleife end-->
                </div>
                <div class="border-top-1 border-bottom-1 surface-border mt-4 py-4">
                    <ul class="list-none m-0 p-0">
                        <li class="flex justify-content-between mb-3">
                            <span class="text-600">Zwischensumme</span>
                            <span class="text-900 font-medium">
                                <span v-if="store.loggedIN">{{ formatCurrency(calcZwischenSumme) }}</span>
                                <span v-else> Anmeldung erforderlich </span>
                            </span>
                        </li>
                        <li class="flex justify-content-between mb-3">
                            <span class="text-600">Versand/Lieferung</span>
                            <span class="text-900 font-medium">
                                <span v-if="store.loggedIN" class="text-900 font-medium">{{
            formatCurrency(calcVersandkosten)
        }}</span>
                                <span v-else class="text-900 font-medium">-</span>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="mt-auto">
                    <div class="flex align-items-center justify-content-between text-xl text-900">
                        <span>Gesamtsumme</span>
                        <span v-if="store.loggedIN" class="text-900 font-medium">{{ formatCurrency(calcGesamtsumme)
                            }}</span>
                        <span v-else>-</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { userStore } from "@/store/userStore";
import { formatCurrency } from "@/utils/formatUtils";
import { removeFromCart, amountChange } from "@/utils/shoppingCartUtils";
import { useConfirm } from "primevue/useconfirm";
import { computed } from "vue";
import iAxios from "@/store/axiosInterface";
import { onMounted } from "vue";

const store = userStore();
const confirm = useConfirm();

onMounted(() => {
    console.log(store.shoppingCart);
})

//eine computed die automatich die zwischensumme aller artikel berechnet
const calcZwischenSumme = computed(() => {
    let sum = 0;
    let url="";
    if (!store.loggedIN) {
        return sum;
    }
    if (store.shoppingCart) {
        for (let i = 0; i < store.shoppingCart.length; i++) {
            if (!store.shoppingCart[i].item.VK) {
                let storedProduct = store.products.find(o => {
                    console.log(o.item_id_company + " - " + store.shoppingCart[i].item.item_id_company);
                    return o.item_id_company == store.shoppingCart[i].item.item_id_company;
                });
                if (!storedProduct) {
                    url = "/auth/customer/itemlist/" + store.shoppingCart[i].item.key;
                    iAxios.get(url).then((response) => {
                        console.log("🚀 ~ file: App.vue:82 ~ response:", response);
                        store.products = response.data.itemList;

                        console.log("🚀 ~ file: App.vue:738 ~ store.products:", store.products);
                    });
                }
/*                 console.log(
                    "🚀 ~ file: App.vue:589 ~ store.shoppingCart[i].item:",
                    storedProduct.VK
                ); */

                //store.shoppingCart[i].item.VK = storedProduct.VK;
            }
            if (store.shoppingCart[i].item.VPE_QTY) {
                sum += store.shoppingCart[i].item.VK * store.shoppingCart[i].item.VPE_QTY * store.shoppingCart[i].quantity;
            } else {
                sum += store.shoppingCart[i].item.VK * store.shoppingCart[i].quantity;
            }
        }
    }
    console.log("🚀 ~ file: App.vue:586 ~ sum:", sum);

    return sum;
});


const calcVersandkosten = computed(() => {
    return 5;
});

const calcGesamtsumme = computed(() => {
    return calcZwischenSumme.value + calcVersandkosten.value;
});

</script>