import {ref} from 'vue';

/** Datumsfunktion für die umwandlung nach dd-mm-yyyy */
export const formatDate = (date,de) => {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 101).toString().substring(1);
    var day = (date.getDate() + 100).toString().substring(1);
    return year + "-" + month + "-" + day;
  };
  
//Datumsfunktion für die umwandlung nach dd.mm.yyyy
export const formatDatum = (value) => {
    const date = new Date(value);
    return date.toLocaleDateString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  export const formatCurrency = (value) => {
    if (value == null) return "";
    return value.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  };