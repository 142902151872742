<template>
    <template v-if="!store.shoppingCart || store.shoppingCart.length == 0 && !orderCreated">
        <Card class="w-fill mt-5" v-if="store.loggedIN">
            <template #content>
                <h1>Keine Artikel im Warenkorb</h1>
            </template>
        </Card>
    </template>
    <template v-else>
        <Sidebar v-model:visible="sideAddressShow" header="Sidebar" position="right"
            class="w-11 h-10 lg:h-10 md:w-8 lg:w-4 p-3">
            <template #container="{ closeCallback }">
                <div class="field w-full">
                    <div>
                        <Button type="button" @click="closeCallback" icon="pi pi-arrow-left" label="Zurück" outlined
                            class="text-color"></Button>
                    </div>
                </div>
                <AdressAuswahl @addressSelected="addressSelected($event)" :addressType="addressType" />
            </template>
        </Sidebar>
        <Card class="w-fill mt-5" v-if="store.loggedIN">
            <template #content>
                <Stepper v-model:activeStep="active">
                    <StepperPanel>
                        <template #header="{ index, clickCallback }">
                            <button class="bg-transparent border-none inline-flex flex-column gap-2"
                                @click="clickCallback">
                                <span :class="[
        'border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center',
        {
            'bg-primary border-primary': index <= active,
            'surface-border': index > active,
        },
    ]">
                                    <i class="pi pi-shopping-cart" />
                                </span>
                            </button>
                        </template>
                        <template #content="{ nextCallback }" class="my-5">
                            <h1>Warenkorb</h1>

                            <CheckoutShoppingCart v-model="body" />

                            <div class="flex pt-5 justify-content-end">
                                <Button label="Weiter" icon="pi pi-arrow-right p-1" iconPos="right"
                                    @click="nextCallback" />
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel>
                        <template #header="{ index, clickCallback }">
                            <button class="bg-transparent border-none inline-flex flex-column gap-2"
                                @click="clickCallback">
                                <span :class="[
        'border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center',
        {
            'bg-primary border-primary': index <= active,
            'surface-border': index > active,
        },
    ]">
                                    <i class="pi pi-user" />
                                </span>
                            </button>
                        </template>
                        <template #content="{ prevCallback, nextCallback }">
                            <h1>Rechnungsanschrift</h1>
                            <CheckoutRechnunganschrift v-model="body" />
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Zurück" severity="secondary" icon="pi pi-arrow-left"
                                    @click="prevCallback" />
                                <Button label="Weiter" icon="pi pi-arrow-right" iconPos="right" @click="nextCallback" />
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel>
                        <template #header="{ index, clickCallback }">
                            <button class="bg-transparent border-none inline-flex flex-column gap-2"
                                @click="clickCallback">
                                <span :class="[
        'border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center',
        {
            'bg-primary border-primary': index <= active,
            'surface-border': index > active,
        },
    ]">
                                    <i class="pi pi-money-bill" />
                                </span>
                            </button>
                        </template>
                        <template #content="{ prevCallback, nextCallback }" class="my-5">
                            <template v-if="!orderCreated">
                                <h1>Zahlungsart</h1>
                                <div>
                                    Welche Zahlungsart möchten Sie für die Zahlung nutzen?
                                    <Dropdown class="flex mt-1" v-model="body.docContent.paymentMethod"
                                        option-label="value" option-value="value"
                                        placeholder="Bitte eine Zahlungsart auswählen"
                                        :options="store.companySettings.paymentKinds.paymentData.filter((o) => o.available == true)" />
                                    <!-- template v-if="formData.paymentKind">
                                <div class="py-3">
                                    <h3>Bemerkung: </h3>
                                    <div v-for="paymentKind in store.companySettings.paymentKinds.paymentData.filter((o) => o.value == formData.paymentKind)">
                                        {{ paymentKind.notice }}
                                    </div>
                                </div>
                            </template-->
                                </div>
                            </template>
                            <template v-else>
                                <div class="p-d-flex p-jc-center p-ai-center">
                                    <div class="flex justify-content-center">
                                        <i class="pi pi-check-circle success-icon"></i>
                                    </div>
                                    <div class="success-message">Die Bestellung wurde erfolgreich angelegt.</div>
                                    <div class="success-message">
                                        <a href="#" v-if="linkData.blob" @click="downloadOrderDoc">Bestellbestätigung
                                            Downloaden!</a>
                                    </div>
                                </div>
                            </template>

                            <div v-if="!orderCreated" class="flex pt-4 justify-content-between">
                                <Button label="Zurück" severity="secondary" icon="pi pi-arrow-left"
                                    @click="prevCallback" />
                                <Button label="Bestellung abschließen" icon="pi pi-arrow-right" iconPos="right"
                                    :disabled="!formData.paymentKind" @click="postOrder" />
                            </div>
                            <div v-else class="flex pt-4 justify-content-between">
                                <div></div>
                                <Button label="Zur Hauptseite" @click="goToMainPage" />
                            </div>
                        </template>
                    </StepperPanel>
                </Stepper>
            </template>
        </Card>
    </template>
</template>
<script setup>
import { userStore } from "@/store/userStore";
import CheckoutShoppingCart from "./CheckoutShoppingCart.vue";
import CheckoutRechnunganschrift from "./CheckoutRechnunganschrift.vue";
import * as shoppingCartUtils from "../utils/shoppingCartUtils";
import { onMounted, ref } from "vue";
import { onBeforeMount } from "vue";
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";
import router from "@/router";
import download from "downloadjs";
import { clearShoppingCart } from "../utils/shoppingCartUtils";
import { AddressService } from "@/service/AddressService";

const store = userStore();

const formData = ref({});

const active = ref(0);
const orderCreated = ref(false);
const toast = useToast();

const linkData = ref({});

const body = ref({
    itemList: store.shoppingCart,
    receiver: {},
    deliverTo: {},
    docContent: {
        shipping: {
            shippingPrice: 0
        },
    },
});

const goToMainPage = () => {
    router.push("/home");
}

const downloadOrderDoc = () => {
    download(linkData.value.blob, linkData.value.docName + ".pdf", "application/pdf");
}

const postOrder = async () => {
    let modBody = JSON.parse(JSON.stringify(body.value));
    let tempItemList = modBody.itemList;
    modBody.itemList = [];
    tempItemList.forEach(entry => {
        entry.item.quantity = entry.quantity;
        entry.item.itemOutPrice = entry.item.VK;

        delete entry.item.VK;

        modBody.itemList.push(entry.item);
    });
    await iAxios.post("/auth/customer/create-order", modBody)
        .then(async (response) => {
            orderCreated.value = true;

            await clearShoppingCart();

            console.log(modBody);
            console.log(response);
            toast.add({
                severity: "success",
                summary: "Bestellung erfolgreich!",
                detail: "Ihr bestellung wurde erfolgreich eingereicht.",
            });

            linkData.value.blob = response.data;
            linkData.value.docName = response.headers.orderdocname;
        })
        .catch((error) => {
            console.log(modBody);
            console.log(error);
            toast.add({
                severity: "error",
                summary: "Bestellung nicht erfolgreich",
                detail: "Ihr bestellung konnte nicht eingereicht werden.",
            });
        });
}

onBeforeMount(() => {
    iAxios.get("/auth/customer/customer-details")
        .then((response) => {
            let customerDetail = response.data.customer;
            body.value.receiver = {
                name1: customerDetail.isPrivateCustomer ? customerDetail.salutation + ' ' + customerDetail.firstName + ' ' + customerDetail.surName : customerDetail.firmenName,
                name2: customerDetail.name2,
                street: customerDetail.street,
                address1: customerDetail.street + ' ' + customerDetail.houseNumber,
                address2: customerDetail.address2,
                postCode: customerDetail.postCode,
                city: customerDetail.city,
            };
        }).catch((error) => {
            toast.add({
                severity: "error",
                summary: "Adressen nicht geladen",
                detail: "Das Laden der Rechnungsadresse hat nicht funktioniert.",
            });
        });
    formData.value.paymentKind = "Bar";
});

</script>

<style>
.p-stepper {
    flex-basis: 40rem;
}

.success-icon {
    font-size: 10rem;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: var(--primary-color) !important;
    padding: 10px;
}

.success-message {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
}

h1,
h3 {
    color: #666;
    justify-self: center;
}
</style>