<template>
  <div>
   <div v-if="loading">Lade...</div>
    <div v-else-if="success"><p>Emailadresse erfolgreich, bestätigt!</p><p>es wird auf die Loginseite weitergeleitet..</p></div>
    <div v-else-if="alreadySuccess"><p>Die Emailadresse wurde bereits bstätigt!</p><p>es wird auf die Webseite weitergeleitet..</p></div>
    <div v-else>{{ errorMessage }}</div>
    </div>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import iAxios from "@/store/axiosInterface";
  import { useRouter } from 'vue-router';
  import { userStore } from '@/store/userStore';
import { nextTick } from 'vue';
  
  const store = userStore();
  const router = useRouter();
  const loading = ref(true);
  const success = ref(false);
  const alreadySuccess = ref(false);
  const errorMessage = ref('');

  onMounted(async () => {
    console.log("mail-confirmation");
  const token = router.currentRoute.value.params.token;
let slug="";

  const url=process.env.VUE_APP_BASE_API_URL_CUSTOMER+"/mail-confirm/"+token;
      try {
      const response = await iAxios.get(url);
      console.log("🚀 ~ file: mailconfirm.vue:30 ~ onMounted ~ response:", response);
      console.log("🚀 ~ file: mailconfirm.vue:36 ~ response.data.slug:", response.data.slug)
      slug="/home/"+await response.data.slug;

      if (response.status==200) {




        success.value = true;
        nextTick();
        errorMessage.value=response.data.value;       
        setTimeout(() => {
          // Nach 1-2 Sekunden zur Loginseite weiterleiten
          store.setRegistrationConfirmed(true);
          console.log("🚀 ~ file: mailconfirm.vue:37 ~ slug:", slug)

          router.push(slug);
        }, 4000);
      } else if (response.status==208){
        store.setRegistrationConfirmed(false);
        alreadySuccess.value = true;
        setTimeout(() => {
          // Nach 1-2 Sekunden zur Loginseite weiterleiten
          console.log("🚀 ~ file: mailconfirm.vue:37 ~ slug:", slug)

         router.push(slug);
        }, 4000);
      }      
      else {
        errorMessage.value = 'Token ungültig.';
      }
    } catch (error) {
      console.error(error);
      errorMessage.value = 'Ein Fehler ist aufgetreten.';
    } finally {
      loading.value = false;
    }
  });

</script>
