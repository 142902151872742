<template>
  <!--div class="response2pdf">
    <transition name="transition-anim">
      <section class="pdf-preview" v-if="pdfFile">
        <button @click.self="closePreview()">&times;</button>
        <iframe :src="pdfFile" width="100%" height="100%" />
      </section>
    </transition>
  </div-->
  <div class="card p-fluid">
    <Loading v-show="loading" />

    <DynamicDialog />
    <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
      display="chip" placeholder="Spalten Ansicht wählen" class="w-full" />
    <DataTable v-model:filters="filters" :value="invoices" editMode="row" scrollable scrollHeight="85vh"
      filterDisplay="row" tableStyle="min-width: 10%" resizableColumns columnResizeMode="fit" stripedRows
      selectionMode="single" dataKey="invoiceDocumentId" sortField="invoiceDocumentId" :sortOrder="-1"
      class="p-datatable-sm" @row-dblclick="downloadInvoice" :virtualScrollerOptions="{
      lazy: false,
      onLazyLoad: loadCustomersLazy,
      itemSize: 46,
      showLoader: true,
      loading: lazyLoading,
      numToleratedItems: 100
    }" ref="dataTableRef">
      <Column headerStyle="width: 2rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" :pt="{ icon: { class: 'text-xl' } }" id="editCustomer" text icon="pi pi-download"
            rounded @click="downloadInvoice({ data })" />
        </template>
      </Column>

      <Column field="docName" header="Rechnungsnummer" footer="Rechnungsnummer" key="docName" :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }" style="min-width: 15%" sortable>

        <template #body="{ data }">
          <template v-if="data.eventAction != 'INVOICE_DRAFT'">
            {{ data.docName }}
          </template>
          <template v-else>
            <span class="font-light">Entwurf</span>
          </template>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
      </Column>
      <Column v-for="col of selectedColumns" :key="col.field" :field="col.field" :header="col.header"
        :footer="col.header" :showFilterMenu="false" sortable
        :sortField="isSortable(col.field) ? col.field + '_us' : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <InputText v-model="filterModel.value" v-tooltip.top.focus="'Zum Anwenden Enter drücken'" type="text"
            @keydown.enter="filterCallback()" class="p-column-filter" />
        </template>
        <template #editor="{ data, field }">
          <InputText v-model="data[field]" />
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>
      <Column field="documentStatus" header="Status" footer="Status" key="documentStatus" :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }" style="min-width: 15%" sortable>
        <template #body="{ data }">
          <template v-if="data.documentStatus === 'INVOICE_CANCELED'">
            <div class="p-inputgroup">
              <Button disabled :label="getActionLabel(data.documentStatus)"
                :severity="getRechnungSeverity(data.documentStatus)" text class="py-2" size=small rounded
                aria-haspopup="true" />
            </div>
          </template>
          <template v-if="data.documentStatus === 'INVOICE_CORRECTION'">
            <div class="p-inputgroup">
              <Button disabled :label="getActionLabel(data.documentStatus)"
                :severity="getRechnungSeverity(data.documentStatus)" text class="py-2" size=small rounded
                aria-haspopup="true" />
            </div>
          </template>
          <template v-if="data.documentStatus === 'INVOICE_DRAFT_OPEN'">
            <div class="p-inputgroup">
              <Button disabled icon="pi pi-download" :label="getActionLabel(data.documentStatus)"
                :severity="getRechnungSeverity(data.documentStatus)" text class="py-2" size=small rounded
                aria-haspopup="true" />
            </div>
          </template>
          <template
            v-if="data.documentStatus != 'INVOICE_DRAFT_OPEN' && data.documentStatus != 'INVOICE_CORRECTION' && data.documentStatus != 'INVOICE_CANCELED'">
            <div class="p-inputgroup">
              <Button :label="getActionLabel(data.documentStatus)" :severity="getRechnungSeverity(data.documentStatus)"
                text class="py-2" size=small rounded iconPos="right" disabled />
            </div>
            <!--Menu :selectedOptionIndex="data.documentStatus == 'INVOICE_OPEN' ? 0 : 1" ref="menu" id="overlay_menu"
              :model="popUpitems" :popup="true" /-->
          </template>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses" optionLabel="label"
            optionValue="value" placeholder="Auswählen" class="p-column-filter" style="min-width: 10%"
            :showClear="true">
            <template #option="slotProps">
              <Tag :value="slotProps.option.label" :class="slotProps.option.label"
                :severity="getRechnungSeverity(slotProps.option.value)" />
            </template>
          </Dropdown>
        </template>
      </Column>

      <!--       <Column headerStyle="width: 2rem; text-align: center" header="" footer=""
        bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" :userData="data" outlined text size="small" icon="pi pi-book" label="Storno"
            v-tooltip="'Korrektur Rechnung anlegen'" severity="danger" rounded @click="openRechnung(data)" />
        </template>
      </Column> -->
    </DataTable>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, markRaw, reactive } from "vue";
import { FilterMatchMode } from "primevue/api";
import { InvoicesService } from "@/service/InvoicesService";
import { formatDatum } from "@/utils/invoiceUtils";
import { formatCurrency } from "@/utils/formatUtils";
import Loading from "@/components/Loading";
import iAxios from "@/store/axiosInterface";
import download from "downloadjs";

/* const columns = ref([

  { field: "customerId", header: "Kundennr" },
  { field: "receiver.name1", header: "Name" },
  { field: "receiver.postCode", header: "PLZ" },
  { field: "doccontent.documentValueNetto", header: "Rechnungsbetrag" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "created_de", header: "Erstellt" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "updated_de", header: "Geändert" },
]); */
const columns = ref([
  { field: "documentValueBrutto", header: "Rechnungsbetrag" },
  { field: "documentValueNetto", header: "Rechnungsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
]);
const startColumns = ref([ columns.value[0], columns.value[2] ]);
/* const startColumns = ref([ { field: "receiver.name1", header: "Name" },  { field: "doccontent.documentValue", header: "Rechnungsbetrag" }]);
 */const selectedColumns = ref(startColumns.value);
const onToggle = (val) => {
  selectedColumns.value = columns.value.filter((col) => val.includes(col));
};

const filters = ref({
  docName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name1: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueBrutto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueNetto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: { value: null, matchMode: FilterMatchMode.CONTAINS },
  customerId: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },

});

function isSortable(field) {
  return field === "updated" || field === "created";
}
const isMobile = ref(false);
const loading = ref(true);

const lazyLoading = ref(false);
const loadLazyTimeout = ref();

const invoices = ref([]);
const virtualInvoices = ref([]);
const dataTableRef = ref();

const statuses = reactive([
  { label: "Offen", value: "INVOICE_OPEN" },
  { label: "Bezahlt", value: "INVOICE_PAID" },
  { label: "Storniert", value: "INVOICE_CANCELED" },
  { label: "Gutschrift", value: "INVOICE_CORRECTION" },
  { label: "Teil Zahlung", value: "INVOICE_PARTIAL" },
]);

const pdfFile = ref();

const lastVirtualInvoiceIndex = ref(0);
const firstVirtualInvoiceIndex = ref(0);
const loadCustomersLazy = (event) => {
  !lazyLoading.value && (lazyLoading.value = true);

  if (loadLazyTimeout.value) {
    clearTimeout(loadLazyTimeout.value);
  }
  //simulate remote connection with a timeout
  loadLazyTimeout.value = setTimeout(() => {
    let tempVirtualInvoices = [...invoices.value];
    let { first, last } = event;
    lastVirtualInvoiceIndex.value = last;
    firstVirtualInvoiceIndex.value = first;

    //load data of required page
    const loadedCustomers = invoices.value.slice(first, last);
    //populate page of virtual cars
    Array.prototype.splice.apply(tempVirtualInvoices, [...[first, last - first], ...loadedCustomers]);

    virtualInvoices.value = tempVirtualInvoices;
    lazyLoading.value = false;
  }, 1200);
};

const downloadInvoice = async (event) => {
  console.log(event.data.invoiceDocumentId)
  let url = process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + "/download-invoice/" + event.data.invoiceDocumentId;

  await iAxios.get(url, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      download(response.data, "Rechnung_" + event.data.invoiceDocumentId + "_" + formatDatum(event.data.docContent.rechnungsDatum) + ".pdf", "application/pdf");
    })
};

const getRechnungSeverity = (statusRechnung) => {
  switch (statusRechnung) {
    case "INVOICE_OPEN":
      return "warning";

    case "INVOICE_PAID":
      return "success";

    case "INVOICE_CANCELED":
      return "danger";

    case "INVOICE_CORRECTION":
      return "Primary";
  }
  return "info";
};

const getActionLabel = (label) => {
  let status = statuses.find((o) => o.value == label);
  if (!status) {
    return label;
  }
  return status.label;
}

async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`Komplett geladen in ${dauer / 1000} Sekunden.`);
}

async function werteLaden() {
  loading.value = true;
  await
    InvoicesService.getInvoices().then((data) => {
      if (data && data.invoices) {
        invoices.value = data.invoices.map((invoice) => ({
          ...invoice,
          name1: invoice.receiver.name1,
          postCode: invoice.receiver.plz,
          documentValueBrutto: formatCurrency(invoice.docContent.documentValueBrutto),
          documentValueNetto: formatCurrency(invoice.docContent.documentValueNetto),
        }));
      }
    });
  loading.value = false;
}
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  checkMobile();
  window.addEventListener('resize', checkMobile);
});

onBeforeMount(async () => {
  await zeitMessen(werteLaden);
  //await werteLaden();
});

function closePreview() {
  pdfFile.value = null
}
</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
