import iAxios from '@/store/axiosInterface';
import { userStore } from '@/store/userStore';
import { useStyle } from 'primevue/usestyle';
import { reactive } from 'vue';

export const quantities = reactive({});

//@memo store ist bereits reactive daher wird das hier nicht nochmal gesetzt
export const addToCart = async (item) => {
    const store = userStore();

    // Überprüfen Sie, ob der Artikel bereits im Warenkorb ist
    let existingItem = null;
    if (!store.shoppingCart) {
        store.shoppingCart = [];
    }
    if (store.shoppingCart.length > 0) {
        existingItem = store.shoppingCart.find((i) => i.item.item_id_company === item.item_id_company && i.item.VPE_QTY === item.VPE_QTY);
    } if (existingItem) {
        // Wenn der Artikel bereits im Warenkorb ist,
        existingItem.quantity += quantities[item.item_id_company];

        let vpeLinkedItem = getVPELinkedItem(existingItem);
        if (vpeLinkedItem) {
            vpeLinkedItem.quantity = existingItem.quantity;
        }

        let linkedItem = getItemsLinkedItem(existingItem);
        if (linkedItem) {
            linkedItem.quantities = existingItem.quantity * existingItem.VPE_QTY;
        }
    } else {
        // Wenn der Artikel noch nicht im Warenkorb ist,
        if (Array.isArray(store.shoppingCart)) {
            let shoppingCartItem = {
                item: { ...item },
                quantity: quantities[item.item_id_company],
            };
            store.shoppingCart.push(shoppingCartItem);
            
            let linkedItem = getItemParametersLinkedItem(item);
            if (linkedItem) {
                linkedItem = { ...linkedItem };

                linkedItem.linkedItem = true;
                linkedItem.linkedVPEItem = false;
                linkedItem.linkedTo = shoppingCartItem;

                store.shoppingCart.push({
                    item: { ...linkedItem },
                    quantity: (shoppingCartItem.item.VPE_QTY ? shoppingCartItem.quantity * shoppingCartItem.item.VPE_QTY : shoppingCartItem.quantity),
                });
            }
            let vpeLinkedItem = getVPEOfPosition(item);
            if (vpeLinkedItem) {
                vpeLinkedItem = { ...vpeLinkedItem };

                vpeLinkedItem.linkedItem = true;
                vpeLinkedItem.linkedVPEItem = true;
                vpeLinkedItem.linkedTo = shoppingCartItem;

                store.shoppingCart.push({
                    item: { ...vpeLinkedItem },
                    quantity: shoppingCartItem.quantity,
                });
            }
        } else {
            console.error('store.shoppingCart is not an array:', store.shoppingCart);
        }
    }
    //  Menge für diesen Artikel zurück setzen
    quantities[item.item_id_company] = 1;
    console.log("🚀 ~ file: CategoryItemsView.vue:232 ~ cartItems:", store.shoppingCart)

    let response = await putCurrentShoppingCart();
    console.log(response);
};

export const findAllLinkedItemsOf = (item) => {
    const store = userStore();

    let linkedItemsOf = [];
    let index = store.shoppingCart.indexOf(item);
    store.shoppingCart.length > index + 1;
    for (let i = index + 1; i < store.shoppingCart.length; i++) {
        let potLinkedItem = store.shoppingCart.at(i);
        if (!potLinkedItem.item.linkedItem) {
            break;
        }
        linkedItemsOf.push(potLinkedItem);
    }
    return linkedItemsOf;
}

const getVPELinkedItem = (item) => {
    let allLinkedItemsOf = findAllLinkedItemsOf(item);
    return allLinkedItemsOf.find(o => o.item.linkedVPEItem);
}

const getItemsLinkedItem = (item) => {
    let allLinkedItemsOf = findAllLinkedItemsOf(item);
    return allLinkedItemsOf.find(o => !o.item.linkedVPEItem);
}

export const getVPEOfPosition = (item) => {
    let store = userStore();

    if (item.VPE_QTY) {
        for (let i = 0; i < item.VPE.list.length; i++) {
            if (item.VPE.list[i].VPE_QTY == item.VPE_QTY && 
                item.VPE.list[i].linkedItem) {
                return store.itemList[item.VPE.list[i].linkedItem.item_id_company];
            }
        }
    }
    return null;    
}

const getItemParametersLinkedItem = (item) => {
    let store = userStore();

    if (!item.itemParameters) {
        return null;
    }
    if (!item.itemParameters.linkedItem) {
        return null;
    }
    let linkedItem = store.itemList[item.itemParameters.linkedItem.item_id_company];

    return linkedItem;
}

const putCurrentShoppingCart = async () => {
    const store = userStore();

    let copyOfShoppingCart = JSON.parse(JSON.stringify(store.shoppingCart));
    let newShoppingCart = [];
    copyOfShoppingCart.forEach(element => {
        element.item.quantity = element.quantity;
        delete element.item.category;

        newShoppingCart.push(element.item);
    });
    return await iAxios.put(process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + '/active-shoppingcart', { itemList: newShoppingCart });
}


export const removeFromCart = async (item) => {
    const store = userStore();

    let toDelete = findAllLinkedItemsOf(item);
    toDelete.push(item);
    
    toDelete.forEach(o => {
        let index = store.shoppingCart.indexOf(o);
        store.shoppingCart.splice(index, 1);
    });

    await putCurrentShoppingCart();
}

export const amountChange = async (item, confirm) => {
    if (item.quantity == 0) {
        confirmRemoveFromCart(item, confirm);
    }
    syncLinkedItems(item);
    await putCurrentShoppingCart();
}

const syncLinkedItems = (item) => {
    let vpeLinkedItem = getVPELinkedItem(item);
    let linkedItem = getItemsLinkedItem(item);
    if (vpeLinkedItem) {
        vpeLinkedItem.quantity = item.quantity;
    }
    if (linkedItem) {
        if (item.item.VPE_QTY) {
            linkedItem.quantity = item.quantity * item.item.VPE_QTY;
        } else {
            linkedItem.quantity = item.quantity;
        }
    }
}

export const clearShoppingCart = async () => {
    const store = userStore();

    store.shoppingCart.length = 0;

    await iAxios.delete(process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + '/active-shoppingcart');
}

// kann private sein.. wird nicht von außen aufgerufen.
const confirmRemoveFromCart = (item, confirm) => {
    let msg = "Soll der Artikel aus dem Warenkorb entfernt werden?";
    confirm.require({
        message: msg,
        header: "Entfernen aus dem Warenkorb",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Ja",
        rejectLabel: "Nein",
        defaultFocus: "reject",
        accept: () => {
            removeFromCart(item);
        },
        reject: () => { },
    });
};

export const calcTotals = () => {
    let store = userStore();
    let sum = 0;
    let url="";
    if (!store.loggedIN) {
        return sum;
    }
    if (store.shoppingCart) {
        for (let i = 0; i < store.shoppingCart.length; i++) {
            if (!store.shoppingCart[i].item.VK) {
                store.shoppingCart[i].item.VK = store.itemList[store.shoppingCart[i].item.item_id_company].VK;
            }
            sum += store.shoppingCart[i].item.VK * store.shoppingCart[i].quantity;
        }
    }
    console.log("🚀 ~ file: App.vue:586 ~ sum:", sum);
    return sum;
}


export const calculateGesamtNetto = (rechnungsDaten) => {
    let gesamtNetto = 0;

    rechnungsDaten.itemList.forEach((position) => {
        let netto = position.item.VK * position.quantity;
        if (position.item.VPE_QTY) {
            netto = netto * position.item.VPE_QTY;
        }
        if (!isNaN(netto)) {
            gesamtNetto += netto;
        }
    });
    rechnungsDaten.docContent.documentValueNetto = gesamtNetto;
}


export const calculateTaxValue = (rechnungsDaten) => {
    const taxValue = {};
    if (rechnungsDaten?.itemList) {
        rechnungsDaten.itemList.forEach((position) => {
            if (position.itemTaxValueFloat !== 0) {
                position.itemTaxValueFloat = position.item.itemTaxValue / 100;
                const ust = calculateGesamtUst(position.itemTaxValueFloat, rechnungsDaten);

                if (!taxValue.taxSum) taxValue.taxSum = ust;
                else taxValue.taxSum += ust;

                if (!taxValue[position.itemTaxValueFloat]) {
                    taxValue[position.itemTaxValueFloat] = ust;
                } else {
                    taxValue[position.itemTaxValueFloat] += ust;
                }
            }
        });
    }
    rechnungsDaten.docContent.taxValue = taxValue;
    return taxValue;
}

export const calculateGesamtBrutto = (itemList) => {
    let gesamtBrutto = 0;
    itemList.forEach((position) => {
        // putTaxValueLegals(position.item);
        let brutto;
        if (position.item.VPE_QTY) {
            brutto =
            (1+(position.item.itemTaxValue/100)) *
            position.item.VK *
            position.item.VPE_QTY *
            position.quantity;
        } else {
            brutto =
            (1+(position.item.itemTaxValue/100)) *
            position.item.VK *
            position.quantity;
        }
        if (!isNaN(brutto)) {
            gesamtBrutto += brutto;
        }
    });
    return gesamtBrutto;
};

export const calculateGesamtUst = (mwstSatz, rechnungsDaten) => {
    let gesamtUst = 0;
    const positionenMitMwst = rechnungsDaten.itemList.filter((position) => {
        return position.itemTaxValueFloat === mwstSatz;
    });
    positionenMitMwst.forEach((position) => {
        let ust =
            position.itemTaxValueFloat* position.item.VK * position.quantity;
        if (position.item.VPE_QTY) {
            ust = ust * position.item.VPE_QTY;
        }
        if (!isNaN(ust)) {
            gesamtUst += ust;
        }
    });
    return gesamtUst;
}
/*
export const putTaxValueLegals = (position) => {
    const taxId = position.itemTaxId;
    const taxOption = getTaxOptionById(taxId);
    if (taxOption?.legalNotice && taxOption?.showInPrints) {
      if (
        !rechnungsDaten.docContent.otherLegalNotices.includes(
          taxOption.legalNotice
        )
      ) {
        rechnungsDaten.docContent.otherLegalNotices.push(taxOption.legalNotice);
      }
    }
  };
  */