
const data={                homePageTitle: "BOAHH!",
homePageSubTitle: "Ein Erlebnis das Niemand vergisst!",
homeImages: [
    "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/b121fa20-0508-11ef-9242-f57f073a7d4f",
    "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/b3ed15f0-0508-11ef-9242-f57f073a7d4f"
],
sliderItems: [
    {
        item_id_company: 20000436,
        EAN: "100000006",
        itemImageUrl: "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/07cf46f8-0508-11ef-9242-f57f073a7d4f",
        itemName: "FLEISCH ALTA"
    },
    {
        item_id_company: 20000434,
        EAN: "100000004",
        itemImageUrl: "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/f47ef0b2-0507-11ef-9242-f57f073a7d4f",
        itemName: "OMA, wieder Afyon, neymissin be"
    },
    {
        item_id_company: 20000439,
        EAN: "100000009",
        itemImageUrl: "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/7c6612da-0508-11ef-9242-f57f073a7d4f",
        itemName: "Fleisch inshaAllah Helal"
    },
    {
        item_id_company: 20000432,
        EAN: "100000002",
        itemImageUrl: "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/e4c56020-0507-11ef-9242-f57f073a7d4f",
        itemName: "Kurbanlik Kuzu Göya"
    },
    {
        item_id_company: 20000438,
        EAN: "100000008",
        itemImageUrl: "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/6979e494-0508-11ef-9242-f57f073a7d4f",
        itemName: "<<<<<<Lokum bu Lokum"
    },
    {
        item_id_company: 20000437,
        EAN: "100000007",
        itemImageUrl: "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/10e5f502-0508-11ef-9242-f57f073a7d4f",
        itemName: "et iste"
    },
    {
        item_id_company: 20000435,
        EAN: "100000005",
        itemImageUrl: "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/fceb65be-0507-11ef-9242-f57f073a7d4f",
        itemName: "Bacak Kol ne bilim ben"
    },
    {
        item_id_company: 20000433,
        EAN: "100000003",
        itemImageUrl: "https://storage.famtura.de/api/files/download/e000e418-d4b2-11ee-878c-f158b950f8ff/ecea83a2-0507-11ef-9242-f57f073a7d4f",
        itemName: "Heyt be, sucugun has-Sucuk!"
    }
]};

export const ProductService = {
    getData(){
        return data;
    },
    getProductsData() {
        return Promise.resolve(this.getData());
    },
};

