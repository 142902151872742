<template>
  <div class="card flex justify-content-center">
    <Stepper v-model:activeStep="active">
       <StepperPanel>
        <template #header="{ index, clickCallback }">
          <button
            class="bg-transparent border-none inline-flex flex-column gap-2"
            @click="clickCallback">
            <span
              :class="[
                'border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center',
                {
                  'bg-primary border-primary': index <= active,
                  'surface-border': index > active,
                },
              ]">
              <i class="pi pi-user" />
            </span>
          </button>
        </template>
        <template #content="{ nextCallback }">
          <div
            class="flex flex-column gap-2 mx-auto"
            style="min-height: 16rem; max-width: 20rem">
            <div class="text-center mt-3 mb-3 text-xl font-semibold">
              Rechnungsanschrift
            </div>
            <div class="field p-fluid">
              <IconField>
                <div class="w-full">
                  <a
                    href="#"
                    v-show="
                      !stepperZusatz.rechnungDaten.zusatzFeldName || formData?.name2 === ''
                    "
                    @click="stepperZusatz.rechnungDaten.zusatzFeldName = true"
                    class="text-xs flex justify-content-end pr-7">
                    Zusatz hinzufügen</a
                  >
                  <FloatLabel>
                    <InputText
                      id="customerCompanyName"
                      v-model="formData.firmenName" />
                    <label for="customerCompanyName">Firmenname</label>
                  </FloatLabel>

                  <InputText
                    v-if="
                      stepperZusatz.rechnungDaten.zusatzFeldName ||
                      formData?.name2?.length > 0
                    "
                    v-model="formData.name2"
                    id="customerCompanyName"
                    placeholder="Zusatz"
                    class="w-10"
                    type="text" /><Button
                    text
                    severity="danger"
                    v-show="
                      stepperZusatz.rechnungDaten.zusatzFeldName ||
                      formData?.name2?.length > 0
                    "
                    icon="pi pi-minus"
                    v-tooltip="'Zusatzfeld entfernen'"
                    @click="entferneZusatzfeldName2(stepperZusatz.rechnungDaten)" />
                </div>
              </IconField>
            </div>

            <div class="formgrid grid">
              <div class="col-12">
                <a
                  href="#"
                  v-show="
                    !stepperZusatz.rechnungDaten.zusatzFeldAddress ||
                    formData.address2 === ''
                  "
                  @click="stepperZusatz.rechnungDaten.zusatzFeldAddress = true"
                  class="text-xs flex justify-content-end pr-7">
                  Zusatz hinzufügen</a
                >
              </div>
              <div class="field col-9">
                <FloatLabel>
                  <InputText
                    id="street"
                    v-model="formData.street"
                    class="w-full" />
                  <label for="street">Straße</label>
                </FloatLabel>
              </div>
              <div class="field p-fluid col-3">
                <FloatLabel>
                  <InputText
                    id="houseNumber"
                    v-model="formData.houseNumber"
                    class="w-full" />
                  <label for="houseNumber">Nr</label>
                </FloatLabel>
              </div>
              <div
                v-if="
                  stepperZusatz.rechnungDaten.zusatzFeldAddress || formData?.address2?.length>0
                "
                class="field p-fluid col-12">
                <InputText
                  v-model="formData.address2"
                  id="customerCompanyName"
                  placeholder="Zusatz"
                  class="w-10"
                  type="text" /><Button
                  text
                  severity="danger"
                  v-show="
                    stepperZusatz.rechnungDaten.zusatzFeldAddress ||
                    formData?.address2?.length>0
                  "
                  icon="pi pi-minus"
                  v-tooltip="'Zusatzfeld entfernen'"
                  @click="entferneZusatzfeldAddress2(stepperZusatz.rechnungDaten)" />
              </div>
              <div class="field p-fluid col-3 pt-2">
                <FloatLabel>
                  <InputText
                    id="postCode"
                    v-model="formData.postCode"
                    class="w-full" />
                  <label for="postCode">PLZ</label>
                </FloatLabel>
              </div>
              <div class="field col-9 pt-2">
                <FloatLabel>
                  <InputText
                    id="city"
                    v-model="formData.city"
                    class="w-full" />
                  <label for="city">Ort</label>
                </FloatLabel>
              </div>
            </div>
            <div class="field p-fluid">
              <FloatLabel>
                <InputText
                  id="phone"
                  v-model="formData.phone"
                  class="w-full" />
                <label for="phone">Telefonnummer</label>
              </FloatLabel>
            </div>

            <div class="field p-fluid">
                <FloatLabel>
                  <InputText
                  placeholder="GmbH, AG, UG, Gbr, e.K. /etc."
                    id="organisationForm"
                    v-model="formData.organisationForm"
                    class="w-full" />
                  <label for="city">Gesellschaftsform</label>
                </FloatLabel>
              </div>
            <div class="field p-fluid">
                <FloatLabel>
                  <InputText
                    id="steuernr"
                    v-model="formData.steuernr"
                    class="w-full" />
                  <label for="city">Steuer-Nr.</label>
                </FloatLabel>
              </div>
              <div class="field p-fluid">
                <FloatLabel>
                  <InputText
                    id="ustId"
                    v-model="formData.ustId"
                    class="w-full" />
                  <label for="city">Ust-ID</label>
                </FloatLabel>
              </div>
            <div class="field p-fluid"></div>
          </div>
          <div class="flex pt-4 justify-content-between">
              <Button v-if="!store.userData.firstLogin"
              label="Abbrechen"
              severity="secondary"
              icon="pi pi-times"
              @click="close()" />
            <Button
              label="Weiter"
              icon="pi pi-arrow-right"
              iconPos="right"
              @click="nextCallback" />
          </div>
        </template>
      </StepperPanel>
      <StepperPanel>
        <template #header="{ index, clickCallback }">
          <button
            class="bg-transparent border-none inline-flex flex-column gap-2"
            @click="clickCallback">
            <span
              :class="[
                'border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center',
                {
                  'bg-primary border-primary': index <= active,
                  'surface-border': index > active,
                },
              ]">
              <i class="pi pi-star" />
            </span>
          </button>
        </template>
        <template #content="{ prevCallback, nextCallback }">
          <div
            class="flex flex-column gap-2 mx-auto"
            style="min-height: 16rem; max-width: 20rem">
            <div class="text-center mt-3 mb-3 text-xl font-semibold">
              Kontaktdaten
            </div>
            <div class="formgrid grid gap-2">
              <div class="field col-12">
                <div class="w-full">
                  <FloatLabel>
                    <InputText
                    class="w-full"
                      id="salutation"
                      v-model="formData.salutation" />
                    <label for="salutation">Anrede</label>
                  </FloatLabel>
                </div>
            </div>
              <div class="field col-12">
                <FloatLabel>
                  <InputText
                    id="firstName"
                    v-model="formData.firstName"
                    class="w-full" />
                  <label for="street">Vorname</label>
                </FloatLabel>
              </div>
              <div class="field col-12">
                <FloatLabel>
                  <InputText
                    id="surname"
                    v-model="formData.surName"
                    class="w-full" />
                  <label for="surname">Nachname</label>
                </FloatLabel>
              </div>
              <div class="field col-12">
              <FloatLabel>
                <InputText
                  id="mobilePhone"
                  v-model="formData.mobilePhone"
                  class="w-full" />
                <label for="mobilePhone">Mobilfunknummer</label>
              </FloatLabel>
            </div>
              <div class="field col-12">
                <FloatLabel>
                  <InputText
                    id="postCode"
                    v-model="formData.emailAddress"
                    disabled
                    class="w-full" />
                  <label for="postCode">Email-Adresse</label>
                </FloatLabel>
              </div>

            </div>


            <div class="field p-fluid"></div>
          </div>
          <div class="flex pt-4 justify-content-between">
            <Button
              label="Zurück"
              severity="secondary"
              icon="pi pi-arrow-left"
              @click="prevCallback" />
            <Button
              label="Fertigstellen"
              icon="pi pi-arrow-right"
              iconPos="right"
              @click="() => saveAndNext(nextCallback)" />
          </div>
        </template>
      </StepperPanel>
      <StepperPanel>
        <template #header="{ index, clickCallback }">
          <button
            class="bg-transparent border-none inline-flex flex-column gap-2"
            @click="clickCallback">
            <span
              :class="[
                'border-round border-2 w-3rem h-3rem inline-flex align-items-center justify-content-center',
                {
                  'bg-primary border-primary': index <= active,
                  'surface-border': index > active,
                },
              ]">
              <i class="pi pi-id-card" />
            </span>
          </button>
        </template>
        <template #content="{ prevCallback }">
          <div
            class="flex flex-column gap-2 mx-auto"
            style="min-height: 16rem; max-width: 24rem">
            <div class="text-center mt-3 mb-3 text-xl font-semibold">
              Daten erfolgreich gespeichert
            </div>
            <div class="text-center">
              <img
                alt="logo"
                src="https://primefaces.org/cdn/primevue/images/stepper/content.svg" />
            </div>
          </div>
          <div class="flex pt-4 justify-content-between">
            <Button
              label="Zurück"
              severity="secondary"
              icon="pi pi-arrow-left"
              @click="prevCallback" />
            <Button v-if="store.userData.firstLogin==true"
              label="Fenster schließen"
              icon="pi pi-check"
              iconPos="right"
              @click="close()" />
            <Button v-if="!store.userData.firstLogin"
              label="Beenden"
              icon="pi pi-times"
              iconPos="right"
              @click="close()" />
          </div>
        </template>
      </StepperPanel>
    </Stepper>
  </div>
</template>

<script setup>
import iAxios from "@/store/axiosInterface";
import { ref, reactive, onMounted } from "vue";
import { userStore } from "@/store/userStore";
import { useRouter } from "vue-router";

const store = userStore();
const emit = defineEmits(['hide']);
const router = useRouter();
const active = ref(0);
const completed = ref(false);

const stepperZusatz = ref({
  rechnungDaten: {
    zusatzFeldName: false,
    zusatzFeldAddress: false,
  },
  lieferungDaten: {
    zusatzFeldName: false,
    zusatzFeldAddress: false,
  },
})

const formData = reactive({
  street: null,
  houseNumber: null,
  address2: null,
  phone: null,
  mobilePhone: null,
  extraAddress: null,
  firmenName: null,
  firstLogin: null,
  firstName: null,
  isPrivateCustomer: false,
  emailAddress: null,
  firstName: null,
  surName: null,
  name2: null,
  postCode: null,
  surName: null,
  salutation: null,
  steuernr: null,
  ustId: null,
  lieferAdresse: { firmenName: "" },
  organisationForm: null,
  profileAttachment: null,
  deliveryAddressSameAsInvoiceAddress: false,
});

const saveData = () => {
  console.log("savaData")
  const url="/auth/customer/customer-details"
  formData.firstLogin = false;
  store.userData.firstLogin = false;
  iAxios.patch(url, formData)
    .then((response) => {
      store.userData = response.data.customer;
      console.log("🚀 ~ file: fillUserDataForm.vue:385 ~ response:", response)
    })


    .catch((error) => {
      console.log(error);
    });
};

function saveAndNext(nextCallback) {
  saveData();
  nextCallback();
}

onMounted(async() => {
  await iAxios.get("/auth/customer/customer-details")
    .then((response) => {
      console.log(response);
      Object.assign(formData, response.data.customer);
      if (!formData.lieferAdresse) {
        formData.lieferAdresse = { firmenName: "" };
      }

      console.log("🚀 ~ file: fillUserDataForm.vue:387 ~ response.data.customer:", response.data.customer)
      console.log("🚀 ~ file: fillUserDataForm.vue:389 ~ formData:", formData)


    })




    .catch((error) => {
      console.log(error);
    });
//Object.assign(formData, store.userData);
//formData.emailAddress = store.emailAdress;
});

const entferneZusatzfeldName2 = (feldGruppe) => {
  feldGruppe.zusatzFeldName = false;
  formData.name2 = "";
};

const entferneZusatzfeldAddress2 = (feldGruppe) => {
  feldGruppe.zusatzFeldAddress = false;
  formData.address2 = "";
};

const close = () => {
  emit('hide');
};
</script>
<style scoped>
.p-stepper {
  flex-basis: 40rem;
}
</style>
