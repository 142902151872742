import { createRouter, createWebHistory } from "vue-router";
import { userStore } from "@/store/userStore";

import Welcome from "@/pages/Welcome.vue";
import MailConfirm from "@/api/mailconfirm.vue";
import CategoryItemsView from "@/views/CategoryItemsView.vue";
import OrderHistoryView from "@/views/OrderHistoryView.vue";
import Checkout from "@/views/Checkout.vue";
import HomeContent from "@/views/HomeContent.vue";
import iAxios from "@/store/axiosInterface";

const routes = [
  // Zuerst spezifische Routen
  {
    path: "/orderHistory",
    component: OrderHistoryView,
    name: "orderHistoryView",
    meta: { requiresAuth: true }
  },
   {
    path: "/home",
    component: HomeContent,
    name: "home"
  },
  {
    path: "/category",
    component: CategoryItemsView,
    name: "categoryItemsView"
  },
  {
    path: "/checkout",
    component: Checkout,
    name: "Checkout"
  },
  {
    path: "/mail-confirm/:token",
    component: MailConfirm,
    name: "mailConfirm"
  },
  // Dann allgemeine Routen
  {
    path: "/home/:companyId",
    component: HomeContent,
    name: "HOME",
  },
  {
    path: "/",
    redirect: "/"
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//Für die Home seite wird das padding wegen dem Video entfernt
router.afterEach((to) => {
  if (to.name === "HOME" || to.path === `/${to.params.companyId}`) {
    document.body.style.padding = "0rem";
    document.body.style.overflow = 'hidden';
    //router.push('/home');
  } else {
    document.body.style.padding = "1rem";
    document.body.style.overflow = 'auto';
  }
});

const getCompanySettings = async (slug) => {
  const store = userStore();
  console.log("getCompanySettings");
    // Nur Daten holen, wenn keine Nachricht angezeigt wird
    console.log("Daten für Slug holen:", slug);
    let url = "/customer/company/" + slug;
    try {
     const response = await iAxios.get(url);
      if (response.status === 200) {
        store.setNotReady(false);
        console.log("Daten erfolgreich geholt:", response);
        store.companySettings = response.data;
        store.companySettings.slug = slug;
      } else {
        console.error("Fehler beim Senden der Daten an Backend");
      }
    } catch (err) {
      store.setNotReady(true);
      console.error("Fehler beim Senden der Daten:", err);
    }
};


router.beforeEach((to, from, next) => {
  const store = userStore();

  // Überprüfen, ob die Route Authentifizierung erfordert und der Benutzer nicht angemeldet ist
   if (to.meta.requiresAuth && !store.userData) {
    return;
  }
 
  const companySlug = to.params.companyId;

  console.log('Navigating to:', to.path);

  if (to.path === '/default') {
    // Spezialfall: /default Handling
    next();
  } else if (companySlug) {
    getCompanySettings(companySlug);

setTimeout(() => {
    if (!store.hasVisited(companySlug)) {
      console.log('Erster Besuch von:', companySlug);
      store.visitCompany(companySlug);
      next();
    } else {
      console.log('Wiederholter Besuch von:', companySlug);
      next();
    }
  }, 100);
  }
   else {
    next();
  }
});
export default router;

